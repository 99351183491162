.logout-button{
    --ion-text-color: #eb445a;
}

.my-custom-class {
    --background: #222;
}
.avatar{
    margin: auto;
}

.alert-photo {
    border-radius: 10% !important;
    overflow: hidden;
}
